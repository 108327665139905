"use strict";

var animations = function () {
    // $('.top-scroll').on('click', function (evt) {
    //     $("html, body").animate({ scrollTop: 0 }, "slow");
    //     evt.preventDefault();
    // });

        // set scene controller

        var controller = new ScrollMagic.Controller();

        // show or hide fixed menu

        new ScrollMagic.Scene({
            triggerElement: '.knowledge',
            triggerHook: '0',
            offset: 100,
            reverse: true
        }).on('start', function () {
            $('#knowlege-list').addClass('run');
        })
        .on('leave', function () {
            $('#knowlege-list').removeClass('run');
        })
        .addTo(controller);

        new ScrollMagic.Scene({
          triggerElement: '.rights',
          triggerHook: '0',
          offset: -300,
          reverse: true
        }).on('start', function () {
          $('#rights-list').addClass('run');
        })
            .on('leave', function () {
              $('#rights-list').removeClass('run');
            })
            .addTo(controller);


};

var textOrphans = function () {
    $('p').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};


var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', { expires: 365 });
        });
    });
};


$(function () {

    animations();
    cookies();
    textOrphans();

});
